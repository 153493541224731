import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { TransitionMask } from '@components'
import { Grid, GridItem } from '@components/grid'
import { ResidencyProps } from '@types'
import { WithData } from '@utils'



const ResidencyTemplate = ({ 
  pageContext: {
    settings,
  },
  data: {
    sanityResidency: {
      title,
      shareImage,
      description,
      gridItems,
    }
  }
}: ResidencyProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    shareImage: shareImage || settings.shareImage,
    keywords: settings.keywords,
    description: description || settings.description,
  }
  
  const parsedTitle = 'Land Base: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: projectSettings.description },
    { name: "twitter:description", content: projectSettings.description },
    { property: "og:description", content: projectSettings.description },
    { property: "og:image", content:  `${projectSettings.shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content:  `${projectSettings.shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
  ]

  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      
      <Grid>
        {gridItems.filter(p => !!p).map((item) => (
          <GridItem 
            key={item._key} 
            {...item} 
          />
        ))}
      </Grid>
      
      <TransitionMask />
    </>
  )
}


export const query = graphql`
  query residency($slug: String!) {
    sanityResidency(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      titleRu
      titlePt
      description
      shareImage {
        asset {
          url
        }
      }
      cardColor {
        hex
      }
      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
        ... on SanityGridItemStrip {
          ...gridItemStripFields
        }
      }
    }
  }
`

const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })

export default WithData(mapDataToProps, ResidencyTemplate)